@import url('https://fonts.googleapis.com/css2?family=Kalam:wght@300;400;700&display=swap');


/* default  setup */ 
*{
    font-family: 'Kalam', cursive;
    padding: 0;
    margin: 0;
    box-sizing: border-box; 
}
html{
    scroll-behavior:smooth;
}

h1, h2, h3, h4,h5, h6, span{
  
   /* text-align: center; */
    line-height:1.25;
} 

p{
    line-height:1.25;
    font-size: 16px;
} 
li{
 list-style-type: none;
}
a{
    text-decoration: none;
    position: relative;
    
}
input, textarea{
  width:100% ;
  background-color: #242424;
  color: #fff;
  outline: none; 
  border: 3px solid #c0c4cc;
  border-top: none;
  border-left: none;
  border-right: none;
  height: 40px;
  transition: all 300ms ease;
}
textarea{
    resize: vertical;
    height: 100px;
    margin-top: 8px;
}
label{
    font-size: 14px;
    font-weight: bold;

}
input:hover, textarea:hover{
border-color:#dcdfe6;
}

input:focus, textarea:focus{
border-color: #f06449;
}




/*reusable css*/


.div-flex{
    flex: 1;
    min-height: 100vh;
    display: flex;   
    
}

.big-row{
  width:100%;
  max-width:1200px;
  margin:0 auto;
      
}
.med-row{
    width:100%;
  max-width:800px;
  margin:0 auto;
  padding: 0 14px;
}
.small-row{
    width:100%;
  max-width:600px;
  margin:0 auto;
}
.container{
    padding: 80px 0;
    
}
/* 
other
*/
.link-hover-effect::after{
content: "";
position: absolute;
height: 4px;
background-color: black;
width: 0;
bottom: -4px;
right: 0;
transition: all 300ms ease;
}
.link-hover-effect:hover:after{
    width: 100%;
    left: 0;
    

}
.link-hover-effect--black::after{
    content: "";
    position: absolute;
    height: 4px;
    background-color: black;
    width: 0;
    bottom: -4px;
    right: 0;
    transition: all 300ms ease;
    }
    .link-hover-effect--black:hover:after{
        width: 100%;
        left: 0;
        
    
    }
    
button{
    cursor: pointer;
  }

section{
    background-color: rgb(245, 245,245);
    transition: all 300Ms ease;

}
.title{
font-size: 120px;
margin-bottom: 12px;
text-align: left;
line-height: 1;
}
.special-color{
    color:#F45B69
    }
.click{
    cursor: pointer;
    transition: all 300ms ease;
}
.click:hover{
 transform: scale(1.1);
}
.click:active{
    transform: scale(0.8);
}
nav,
.scroll,
.header{
    opacity: 1;
    visibility: visible;
    transition: all 600ms  800ms;
}
 .modal--open nav,
.modal--open  .scroll,
.modal--open  .header{
    opacity: 0;
    visibility: hidden;
    transition: all 400ms;
}
.dark-theme .title,
.dark-theme .section__title,
.dark-theme .section__sub-title, 
.dark-theme .nav-link-a,
.dark-theme .fa-adjust,
.dark-theme .header__para{
color: white;
}
.dark-theme section{
background-color: #242424;
}
.dark-theme #logo-img{
    filter: none;
}

.dark-theme .scroll__icon{
    border-color: white;
}
.dark-theme .scroll__icon:after, 
.dark-theme .link-hover-effect::after{
    background-color: white;
}
.dark-theme .mail__btn{
    background-color: white;
    color: #242424;
}



/*

LANDING 

*/
#landing-page{
    min-height: 100vh;
   
}

.header{
    position: absolute;
    top: 50%;
    left:50%;
    transform: translate(-50%, -50%);
    width:100%;
    max-width:1000px;
    padding:0px 30px;
    margin-top: 80px;
}

.header__para{
    font-size: 32px;
   line-height: 2;
   max-width: 450px;
    }
.social__list{
        margin-top: 16px;
        display: flex;
    }
.social__link{
       background-color: #F13030;
        color:white;
        padding:6px;
        width: 32px;
        height: 32px;
        font-size:14px ;
        margin-right: 12px;
        align-items: center;
        display: flex;
        justify-content: center;
        border-radius: 50%;
        
    }
.mail__btn{
  width: 70px;
   height:70px;
   font-size: 32px;  
   border-radius: 50%;
   border: none;
   background-color: #242424;
   color: white;
   position:fixed;
   bottom: 32px;
   right:40px;
   z-index:100;
   box-shadow: 0 12px 30px rgb(0, 0, 0, 0.5)


}
.scroll{
     position: absolute;
     bottom:20px;
     left: 50%;
     transform:  translateX(-50%);
}
.scroll__icon{
    width:20px;
    height: 30px;
    border: 2px solid #242424;
    border-radius:  24px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.scroll__icon::after{
    content:'';
    width:4px;
    height: 6px;
    background-color: #242424;
    border-radius: 2px;
    animation: scroll 1000ms  infinite alternate-reverse;

}
@keyframes scroll{
    0%{
        transform: translateY(3px);

    }
    100%{
        transform:  translateY(-3px);
    }
}
/*

NAV BAR 

*/
nav{
    
    height:100px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
}
.nav-list{
display: flex;
}

.nav-link-a{
  
    margin: 0px 12px;
    color: #242424;
    font-size: 16px;
    font-weight: 700;

}
.fa-adjust{
    font-size:20px; 
}

#logo-img{
 height: 50px;
 filter: invert(1);
}

/*

MODAL

*/

.modal--open .modal{
    z-index: 60;
    box-shadow: 0 20px 80px 0 rgb(0, 0, 0, 0.55);
    visibility: visible;
}
.modal{
   position: absolute;
   top:50%;
   left:50%;
   transform: translate(-50%, -50%);
   width: 90%;
   max-width: 1100px;
   height: 700px;
   visibility: hidden;
   border-radius: 20px;
   overflow: hidden;
   display:flex;
   z-index: -1;
   transition: visibility 1s, z-index 1s, box-shadow 0.5s ease;

}
.modal__half{
    width:50%;
    padding: 40px 72px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    transition: all 300ms ease-in;
}
.modal--open .modal__about{
    transform: translateX(0%);
}
.modal--open .modal__contact{
    transform: translateX(0%);
}

.modal__about{
    background-color: rgb(245, 245,245);
    transform: translateX(-110%);
    
}
.modal__contact{
    background-color: #242424;
    color: white;
    transform: translateX(110%);
}
.modal__title{
    font-size: 26px;
}
.modal__sub-title{
    margin: 12px 0 24px 0;
    font-size: 14px;
}
.modal__para{
    margin-bottom: 12px;
    line-height: 1.75;
}
.modal__language:hover .language__name{
    transform: scale(1);
}
.modal__language:hover{
    filter: brightness(80%);
    transform: scale(0.9);
}

.modal__languages{
    display:flex;
    flex-wrap: wrap;

}
.modal__language img{
    width: 100%;
}
.modal__language{
  width: 25%; 
  padding: 16px; 
  position: relative;
  display:flex;
  flex-direction: column;
  align-items: center;
  transition: all 300ms ease;
}
.language__name{
position: absolute;
bottom: -10px;
transform: scale(0);
transition: all 300ms ease;
}
.form__item{
    margin-bottom: 20px;
}
.form__submit{
    background-color: #f06449;
    border: 2px solid#f06449;
    color: white;
    font-weight: 700;
    width: 100%;
    max-width: 240px;
    padding: 12px 24px;
    font-size: 20px;
    transition: all 300ms ease;
}
.form__submit:hover{
    border-color: #fff;
    background-color: transparent;
}
.form__submit:active{
    border-color: #f06449;
    color: #f06449;
}
.modal__overlay{
    position: absolute;
    left:0;
    top:0;
    width: 100%;
    height: 100%;
    display:none;
    align-items: center;
    justify-content: center;
    z-index: -1;
}
.modal__overlay--loading{
    background-color:#242424;;
    font-size: 80px;
    z-index: -1;
}
.modal__overlay--success{
 background-color: #4bb543;  
 font-size: 40px;
  font-weight: 700;
  text-align: center;
  padding:28px
}
.modal__overay--visible{
    z-index: 1;
    display: flex;
}
.fa-spinner{
animation: spinner 750ms infinite linear;
}
@keyframes spinner{
    0%{
        transform: rotate(0);
    }
    100%{
        transform: rotate(360deg);
    }
}
.modal__exit{
    position: absolute;
    top:30px;
     right:40px;
     color:white;
     font-size: 36px;
     z-index: 100;

}

/*

Projects  section 

*/
#projects .container{
    padding-top: 160px;
}
.section__title{
 text-align: center;
 font-size: 32px;
 margin-bottom: 60px;
}
.project{
    margin-bottom: 100px;
}
.project:last-child{
    margin-bottom: 40px;
}
.project__img{
width:100%;
transition: all 500ms ease;

}
.project__wrapper{
display:flex;
 box-shadow:0 20px 80px  rgba(0, 0, 0, 0.45) ; 
 border-radius: 20px;
 overflow: hidden;
 position: relative;


}
.project__wrapper:before{
   content:""; 
   position: absolute; 
   top: 0;
   left: 0;
   height: 100%;
   width: 100%; 
   background-color: #1c1d25;
   opacity: 0;
   transition: opacity 450ms ease;
   z-index: 2;

   
   }
   .project:hover .project__wrapper:before{
    opacity: 0.7;
   }
   .project:hover .project__img{
    transform: scale(1.07);
    filter: blur(5px) ;

   }
   .project:hover .project__description{
    opacity: 1;
    transform: translateY(-50%);
   }

.project--group{
padding-top: 60px;
   }
.project__description{
position: absolute;
top: 50%;
left: 90px;
transform: translateY(1000px);
max-width: 550px;
z-index: 3;
opacity: 0;
transition:  transform 450ms, opacity 300ms;

}
.project__description--title{
    font-size: 40px;
}
.project__description--para{
    margin: 16px 0;
}
.project__description--link{
    font-size: 20px;
    margin-right:16px ;
    text-decoration: none;
}

.project__description--title, .project__description--sub-title, .project__description--para,.project__description--link{
    text-align: left;
    color: white;
}
/*Footer */
footer{
    position: relative;
    background-color: #F6E8EA;
}
.footer__row{
    display: flex; 
    flex-direction: column;
    align-items: center;
    justify-content: center; 
   padding: 8% 0; 
}
.footer__anchor{
 position: relative;
 margin-bottom: 20px;    
}
.footer__anchor:hover .footer__logo--popper{
   transform: translateX(60px); 
   opacity: 1;
}

.footer__logo--img{
  width: 140px;
    
}

.footer__logo--popper{
  color:#F45B69;
  position: absolute; 
  right:0;
  top: 30px;
  opacity: 0;
  transition: all 300ms ease ;
 
}
.footer__links--list{
    margin-bottom: 20px;
    width: 100%;
    max-width: 500px;
    display: flex;
    justify-content: space-around;
   
}
.footer__link, .footer__copyright{
color: #22181C;

}
/*
SHAPES
*/
.shape{
    position: fixed;
    
}
.shape--0{
    top: 15vh;
    left: 5vw;
}
.shape--1{
    top: 15vh;
    left: 50vw;
}
.shape--2{
    top: 15vh;
    left: 80vw;
}
.shape--3{
    top: 50vh;
    left: 5vw;
}
.shape--4{
    top: 50vh;
    left: 50vw;
}
.shape--5{
    top: 50vh;
    left: 80vw;
}
.shape--6{
    top: 80vh;
    left: 5vw;
}
.shape--7{
    top: 80vh;
    left: 50vw;
}
.shape--8{
    top: 80vh;
    left: 80vw;
}

/*Small phones, tablets, large smartphone */ 
@media (max-width: 768px){
.title{
    font-size: 80px;
}
.header__para{
    font-size: 20px;
}
.modal{
    top:0;
    left: 0;
    transform:none;
    height:auto;
    width: 100%;
    flex-direction: column-reverse;
    border-radius: 0;
}
.modal__half{
    width:100%
}
.project__description--para{
    font-size: 14px;
}
.project__description{
    left: 30px;
    padding-right: 30px;
}


} 
/*Small Phones*/
@media (max-width: 480px){
.nav-items:first-child{
display:none;
} 
.title{
    font-size: 52px;
}
.header__para{
    font-size: 18px;
}  
.project__description{
    padding: 0;
    left:0;
    width:100% ;
}
.project__description--para{
    display: none;
}
.project__description--links{
    display: flex;
    justify-content: center;
   
}
.project__description--sub-title{
    text-align: center;
    margin: 12px 0px;
}
.project__description--title{
  line-height:1;
  font-size: 32px;
  text-align: center;
}
.modal__half{
    padding:40px
}

}

 